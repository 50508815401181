import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      description: "Portfolio von Florian Walzel: Designlehre, Publikationen und Vorträge.",
      type: "website"
    }
  },
  {
    path: "/publications",
    name: "Schriften",
    meta: {
      keywords: "Florian Walzel, Papers, Articles, Books",
      description: "Publikationsliste, Buchbeiträge und Artikel von Florian Walzel",
      type: "list"
    },
    component: () =>
      import("../views/Publications.vue")
  },
  {
    path: "/publication/:id",
    name: "Schrift",
    component: () =>
      import("../views/Publication.vue")
  },
  {
    path: "/teachings",
    name: "Designlehre",
    meta: {
      keywords: "Florian Walzel, Design teaching, Workshops, Seminars, Courses",
      description: "Designlehre von Florian Walzel: Vorlesungen, Seminare und Kurse",
      type: "list"
    },
    component: () =>
      import("../views/Teachings.vue")
  },
  {
    path: "/teaching/:id",
    name: "Lehrstück",
    props: true,
    component: () =>
      import("../views/Teaching.vue")
  },
  {
    path: "/lectures",
    name: "Vorträge",
    meta: {
      keywords: "Florian Walzel, Design lectures, Performance Lectures, Talks",
      description: "Öffentliche Vorträge, Diskussionen und Performance Lectures von Florian Walzel",
      type: "list"
    },
    component: () =>
      import("../views/Lectures.vue")
  },
  {
    path: "/lecture/:id",
    name: "Vortrag",
    props: true,
    component: () =>
      import("../views/Lecture.vue")
  },
  {
    path: "/miscellaneous",
    name: "Vermischte Arbeiten",
    component: () =>
      import("../views/Miscellaneous.vue")
  },
  {
    path: "/misc/:id",
    name: "Vermischte Arbeit",
    props: true,
    component: () =>
      import("../views/Misc.vue"),
  },
  {
    path: "/about",
    name: "Über",
    component: () =>
      import("../views/About")
  },
  {
    path: "/legal-agreement",
    name: "Impressum & Datenschutz",
    component: () =>
      import("../views/LegalAgreement.vue")
  },
  {
    path: "/404",
    name: "404",
    component: () =>
        import("../views/PageNotFound.vue")
  },
  {
    path: "/:pathMatch(.*)*",
    component: () =>
      import("../views/PageNotFound.vue")
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(() => {
  // always scroll to page top
  window.scrollTo(0, 0)
})

export default router;