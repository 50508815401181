<template>
  <div id="up-to-top" class="invisible hidden">
    <a href="#" class="as-h1 a-icon">&uarr;</a>
  </div>
</template>

<script>
export default {
  name: "UpToTop",
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const d = document;
      const $upToTop = d.getElementById("up-to-top");
      if (d.body.scrollTop > 280 || d.documentElement.scrollTop > 280) {
        $upToTop.classList.remove("hidden");
        setTimeout(() => { $upToTop.classList.remove("invisible") }, 1);
      }
      else $upToTop.classList.add("invisible");
    }
  }
}
</script>

<style scoped>
  #up-to-top {
    background-size: 30px 35px;
    width: 30px;
    height: 35px;
    position: fixed;
    cursor: pointer;
    top: 65px;
    right: 15px;
    z-index: 312;
    transition: all 1s ease;
  }
  #up-to-top.invisible {
    opacity: 0;
    transform: translateY(30px);
    cursor: default;
  }
  #up-to-top.invisible > a:hover {
    cursor: default;
  }
  @media screen and (max-width: 480px) {
    #up-to-top {
      top: 50px;
      right: 9px;
    }
    #up-to-top .a-icon {
      font-size: 42px;
    }
  }
</style>