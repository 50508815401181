<template>
  <!-- Footer -->
  <footer>
    <router-link to="/legal-agreement" active-class="active">Impressum & Datenschutz</router-link>
  </footer>
</template>
<script>

export default { };

</script>

<style scoped>
  footer {
    position: absolute;
    bottom: 1.2rem;
    right: 10%;
    text-align: right;
  }
  @media screen and (max-width: 768px) {
   footer {
     bottom: .8rem;
     right: 5%;
   }
  }
</style>